@charset "UTF-8";
ul, li {
  margin: 0;
  padding: 0;
  list-style-type: none; }

li {
  padding-left: 10px; }

.error-info {
  position: absolute;
  top: 0px;
  width: 250px;
  padding: 15px;
  background: #fefefe;
  font-size: .875em;
  border-radius: 5px;
  box-shadow: 0 1px 3px #ccc;
  border: 1px solid #ddd;
  left: 105%; }

.error-info::before {
  content: "\25C0";
  position: absolute;
  top: 8px;
  left: -11px;
  font-size: 14px;
  line-height: 14px;
  color: #fff;
  text-shadow: none;
  display: block; }

.error-info li {
  text-align: left; }

h4 {
  font-size: 18px; }

.valid {
  color: green; }

.valid:before {
  position: relative;
  left: -8px;
  content: "✔"; }

.invalid {
  color: red; }

.invalid:before {
  position: relative;
  left: -8px;
  content: "✖"; }

.error {
  padding: 4px 8px;
  color: red; }

.info-box {
  z-index: 10; }

.error-box {
  z-index: 1; }

.loadmorewrapper {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  height: 50px;
  left: 0;
  margin-top: 30px;
  margin-bottom: 20px;
  border-color: #646a92;
  border-bottom-color: transparent; }

.loadmorewrapper .load-more, .loadmorewrapper .load-more:hover {
  background: #646a92;
  color: #fff;
  margin: 0 auto;
  width: 200px;
  border-color: #646a92; }

.loadmorewrapper .css-1696ujm-style {
  border-color: #646a92 #646a92 transparent; }

.loadmorewrapper .load-more:focus {
  box-shadow: 0 0 0 0.2rem rgba(100, 106, 146, 0.5); }
