@import "../scss/variables/global";
@import "../scss/mixins";

.sidebar {
    width: 165px;
    min-height: calc(100vh - 96px);
    padding-top: 20px;
    @include transition($duration: 0.25s);
    position: relative;
    z-index: 0;                  
    ul {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        li {               
            a {
                display: block;
                color: #fff;
                padding-left: 48px;
                background-color: $theme-color;
                position: relative;
                margin-bottom: 0.5rem;
                border-top-left-radius: $border-radious;
                border-bottom-left-radius: $border-radious;
                line-height: 1.25;
                height: 45px;
                font-size: 13px;
                padding-top: 7px;
                &.active {
                    background-color: $secondary-color;
                    color: $theme-color;
                }
                &:before {
                    content: '';
                    width: 40px;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    background-image: url(../assets/img//sprite2.png);
                    z-index: 100;
                    background-repeat: no-repeat;
                    top: -1px;
                }
                &.link-enterprises
                {                          
                    &:before {
                        background-position: -73px -73px;
                    }
                    &.active:before {
                        background-position: -73px -8px;
                        
                    }

                }
                &.link-user {                       
                    &:before {                    
                        background-position: -10px -73px;
                    }
                    &.active:before {
                        background-position: -10px -9px;
                    }
                }
                &.link-teams {                       
                    &:before {                    
                        background-position: -138px -73px;
                    }
                    &.active:before {
                        background-position: -138px -9px;
                    }
                }
            }
            
        }
        
    }
    .navbtn {
        background-image: url(../assets/img//sprite2.png);
        background-position: -205px -76px;
        background-color: $theme-color;
        color: #fff;
        font-size: 25px;
        padding-top: 4px;
        padding-bottom: 8px;
        line-height: 1;
        padding-left: 10px;
        padding-right: 10px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-bottom: 0.5rem;
        height: 40px;
        width: 40px;
        }    
}
.slide .sidebar {
    width: 58px;
    .navbtn {
        background-position: -205px -13px;
    }
}
.main-panel {
    max-width: calc(100% - 165px);
}
.slide + .main-panel {
    max-width: calc(100% - 58px);
}