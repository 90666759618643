@import "../../scss/variables/global";
@import "../../scss/mixins";
body {
    overflow-x: hidden;
}
.Login_container {
    background: url(../../assets/img/loginBackground.jpg) no-repeat center center fixed;    
    background-size: cover;
    height: 100vh;
    @include flex-align-center(column); 
    color: #fff;     
  }

  .Login {
    @include flex-layout;
    max-width: 700px;
    background-color: $theme-color;
    border-radius: 10px;
        &.oveflow-hidden {
            overflow: hidden;
        }   
        &.loading {
            opacity: 0.4;
            button {
                cursor: default !important;
            }
        }
        > div {
           @include flex-size($basis: 100%);
            position: relative;
            z-index: 10;
                img.Login_info {
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;                
            } 
        }     
        .Login_form {
            padding: 0 25px;
            @include flex-justify-center(column);           
        } 
        @media screen and (max-width: 576px){
            .Login_form {
                // Model one
                // max-width: 100%;
                // padding: 21px;
                // width: 373px;
                // height: 393px;

                // Model two
                max-width: 100%;
                height: 100vh;
            }  
          }

          .Login_img_sm_width{
            width: 250px;
          }        
        
        .form-info {            
            padding: 2px 6px;
            border-radius: $border-radious;
            width: 100%;
            font-size: 12px;
        }
        .Login_error {
            background: $error-theme;
            color: $error-color;
            font-size: 12px;
            border-radius: 2px;
        }
        .success {
            background: $success-theme;
            color: $success-color;
        }
        
        img {
            max-width: 100%;            
            }       
        
        .Login_logo {
            @include absolute-center;
            width: 250px;
        }

        .Login_logo_sm {
            padding: 3px;
            margin-bottom: 23px
        }
        
        input {
            background-color: transparent;
            border-color: #fff;
            color: $secondary-text-color;
            &:focus {
                color: $secondary-text-color;
                background-color: transparent;
                border-color: $secondary-text-color;
                outline: 0;
                box-shadow: 0 0 0 0.2rem 0 0 0 0.2rem rgb(74, 84, 146);
            }
            &::placeholder {
                color: #878db3;
            }
        }       
        .f-link {
            color: $secondary-text-color;
            font-size: 10px;
        }
        button:not(.btn-link) {
           background-color: $secondary-color;
           width: 100%;
           font-weight: 600;
        }
        h1 {
            font-size: 1.5rem;
        }
        h1, h4 {
            color: $secondary-color;
            margin-bottom: 1.5rem;
        }
  }
  .css-1696ujm-style {
      position: absolute;
      z-index: 10;
  }
  .form-message {
      color: #fff;
      font-size: 14px;
  }
  .hidden {
      visibility: hidden;
  }
  .Login_division {
      display: flex;
      transform: translatex(0);
      -webkit-transition: 0.1s ease-in-out;
    -moz-transition: 0.1s ease-in-out;
    -o-transition: 0.1s ease-in-out;
    transition: 0.1s ease-in-out;
    z-index: 0 !important;
  }
  .Login_division.verify {    
        // transform: translatex(-100%);
        transform: translatex(0%);
    }
  
  .Login_division .Login_form {
    width: 100%;
    flex: 0 0 100%;
  }
  .btn-link {
    color: #5279d0;
    font-size: 14px;
    text-decoration: underline;
  }

  @media screen and (max-width: 576px){
      .Login {
        width: 100%;
        border-radius: 0px;
      }
  }

  @media screen and (max-width: 550px){
        .error-info {
            position: sticky !important;
        }
    }