@import "./scss/variables/global";
@import "./scss/mixins";

.App {
    background: #e2e3e6;
    min-height: 100vh;
}
.sprite-bck.log-out {
    background-image: url(./assets/img//sprite2.png);
    width: 50px;
    height: 42px;
    background-position: -263px -10px;
    display: inline-block;
}
.page-content-wrapper {
    @include flex-layout;  
   
    .main-panel {
        background-color: #fff;
        padding-top: 20px;
        padding-left: 25px;
        padding-right: 25px;
        width: 100%;
        position: relative;
        margin-bottom: 25px;       
        h1 {
            font-size: 20px;
            color: $theme-color;
            font-weight: 700;
            margin-bottom: 20px;
        }
        .nav {
            // width: 100%;
            li {
                background-color: $secondary-color;
                color: $theme-color;
                padding: 3px 8px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                
            }
        }
    }
}
.signup-form {
    margin-top: 10px;
    border: 1px solid #ddddde;
    border-radius: 4px;
    padding: 15px;
    box-shadow: -1px 0px 2px #dfe0e6;
    .form-group {
        flex: 0 0 25%;
        padding-right: 20px;
        max-width: 300px;
    }
}

.form-control, .custom-select { 
    height: calc(2rem + 2px);
}
.table-box .custom-control {
    padding-left: 0;
    position: relative;
}
.table-box .custom-control-label {
    cursor: pointer;
    position: static;
    &:before {
        @include  absolute-center;
        border-radius: 2px;
        pointer-events: inherit;   
    }
    &:after {
        @include  absolute-center;       
    }
}
.pointer {
    cursor: pointer;
}

//Table 
table tr:nth-child(even) {
    background: whitesmoke;

}
.table .active {
    background-color: #bbbed0;
    color: #fff;
}
.table thead tr {
    background: #646a92;
    color: #fff9f9;
}
.table thead th {
   font-weight: normal;
   cursor: pointer;
}
.loader-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
}
.loader-wrapper div {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.custom-ui {
    text-align: center;
    width: 500px;
    padding: 40px;
    background: #fff;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
    color: #333;
}
.custom-ui > h1 {
    margin-top: 0;
}

.custom-ui > button {
    width: 160px;
    padding: 10px;
    border: 1px solid #fff;
    margin: 10px;
    cursor: pointer;        
    font-size: 14px;
    color: #fff;
    background-color: #6c757d;
    border:1px solid #6c757d;
    border-radius: .25em;
}

.btn-delete {
    background: #fc7169 !important;
    border-color: #fc7169 !important;
}

.btn-disable {
    background: rgb(216, 165, 4);
    border-color: rgb(216, 165, 4);    
}

.react-confirm-alert-overlay {
    background: rgba(0, 0, 0, 0.6) !important;
}
.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6) !important;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    opacity: 0;
    -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    z-index: 100;
}
.nav-wrapper {
    width: 100%;
    display: flex;
    border-bottom: 4px solid #222952;
    margin-top: 10px;
}
.user-filter select {
    width: 150px;
    padding: 0.1rem .75rem 0.1rem 0.75rem;
    font-size: 14px;
    height: 2rem;
}
.user-filter button  {
    padding: 2px 6px;
    border-radius: 0;
    color: #fff;
    min-width: 100px;
}

    tr.remove td, tr.remove th {
        padding: 0 !important;
    } 

  tr.remove td div, tr.remove th div.custom-control {
    max-height: 0;
    min-height: 0 !important;
    padding: 0 10px;
    box-sizing: border-box;
    overflow: hidden;
    transition: max-height 0.5s, padding 0.5s, min-height 0.5s;
  }
  .react-select {
      width: 150px;
  }

  .react-select.react-search {
      width: 200px;
      margin-left: 2rem;
  }

  .react-select .react-select__control {   
    background: #429ad6;
    border-color: #429ad6;
    border-radius: 0 !important;
    box-shadow: none;
    font-size: 14px;
    min-height: 30px;
    cursor: pointer;
  }
  .react-select.yellowctrl .react-select__control {
    background: #d8a504;
    border-color: #d8a504;
  }
  
  .react-select .react-select__placeholder {
      color: #fff;
  }
  .react-select .react-select__control:hover {
    border-color: #429ad6;
  }
  .react-select.yellowctrl .react-select__control:hover {
    border-color: #d8a504;
  }

  .react-select .react-select__control--is-focused, .react-select .react-select__control--is-focused:hover {
    border-color: #429ad6;
  }
  .react-select.yellowctrl .react-select__control--is-focused, .react-select.yellowctrl .react-select__control--is-focused:hover {
    border-color: #d8a504;
  }
  .react-select .react-select__menu-list {
    border-top: 1px solid rgb(34, 41, 82) !important;
  }

  .react-select.yellowctrl .react-select__menu-list {
    border-top: 1px solid #a78006 !important;

  }

  .react-select .react-select__single-value {
      color: #fff;
  }
  .react-select__indicator-separator {
      display: none;
  }
  .react-select .react-select__indicator, .react-select .react-select__indicator:hover {
      color: #fff;
  }
  .react-select .react-select__menu {
    background: #429ad6;
    border-color: #429ad6;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    top: 22px !important;
    border-radius: 0 !important;
    font-size: 14px;
  }
  .react-select.yellowctrl .react-select__menu { 
    background: #d8a504;
    border-color: #d8a504;
  }

  .react-select .react-select__menu-list {
      padding-top: 0;
      padding-bottom: 0;
  }

  .react-select .react-select__option {    
    color: #fff !important;
    cursor: pointer;
    padding: 4px 6px;
  }
  .react-select .react-select__option:hover {
    background-color: #3b7fc7 !important;
    border-color: #3b7fc7;
    color: #fff !important;
  }

  .react-select.yellowctrl .react-select__option:hover {
    background-color: #c59a0c !important;
    border-color: #c59a0c;
  }

  .react-select .react-select__dropdown-indicator {
      padding: 4px 6px;
  }
  
.react-select .react-select__option--is-focused {
    background-color: #3b7fc7 !important;
    border-color: #3b7fc7;
  }
  .react-select.yellowctrl .react-select__option--is-focused {
    background-color: #b68f10 !important;
    border-color: #b68f10;
  }

  .react-select .react-select__option--is-selected {
    background-color: #2a5c91 !important;
    border-color: #2a5c91
  }
  .react-select.yellowctrl .react-select__option--is-selected {
    background-color: #b68f10 !important;
    border-color: #b68f10;
  }
  .async-select {
    width: 250px;
  }
  .async-select .react-select__control {
    min-height: 30px;
    font-size: 14px;
    border-radius: 0;
  }
  .async-select .react-select__value-container {
    padding: 0 8px;

  }
  .async-select .react-select__dropdown-indicator {
    padding: 4px 6px;
}
.async-select .react-select__menu {
  margin-top: 1px;
  border-radius: 0;
  border: 1px solid #c5ced4 !important;
}
.react-select__indicator.react-select__clear-indicator {
  padding: 0;
}

.alert-custom {
    background: #646a92;
    color: #fff;
    padding-top: 0.8em;
    padding-bottom: 0.8em;
}
  
.custom-react-select-width {
    width: 100% !important;
}
.custom-react-select-width .custom-select__control {
    min-height: 38px;
    max-height: 38px;
}
  
.react-select-custom-width-common-table {
    width : 165px !important;
}
.capitalize {
    text-transform: capitalize;
  }
  .sort-icon {
      display: inline-block;
      margin-left: 4px;
  }

  #search-a {
      fill: #6c757d;
  }
  .searcharea > div:first-child {
    position: relative;
  }
  .searcharea input {
    height: 34px;
    font-size: 14px;
    border-radius: 2px;
    border-color: #e1e2e2;
    padding: 0.175rem 0.75rem;
    padding-left: 30px;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-color 150ms ease-in, background-image 150ms ease-in, background-size 150ms ease-in;
    background-color: #fbfbfb;
  }
  .searcharea input:focus {
    background-color: white;
    background-image: linear-gradient(to top, #0079a5, #0079a5 2px, transparent 2px, transparent 100%);
    background-size: 100% 100%;
    box-shadow: none;
    border-color: #e1e2e2;
    //-webkit-box-shadow: 0 1px 1px -1px rgba(153, 153, 153, 0.2), 0 4px 4px -2px rgba(153, 153, 153, 0.2), inset 0 0 0 1px rgba(0, 0, 0, 0.16);
   // box-shadow: 0 1px 1px -1px rgba(153, 153, 153, 0.2), 0 4px 4px -2px rgba(153, 153, 153, 0.2), inset 0 0 0 1px rgba(0, 0, 0, 0.16);
}
  .euiFormControlLayoutIcons {
    position: absolute;
    top: 2px;
    left: 8px;
  }
  .table-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;    
    border: 1px solid #dee2e6;
    margin-bottom: 20px;    
  }
  .body-wrapper {
    overflow: auto;
    max-height: 500px;
    width: 100%;
  }

  .HmiBasicTable-loading:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 3px;
    background-color: #ffc000;
    -webkit-animation: HmiBasicTableLoading 1000ms linear;
   animation: HmiBasicTableLoading 1000ms linear;
   -webkit-animation-iteration-count: infinite;
   animation-iteration-count: infinite;
   top: 50px;
    
}
.reset-table-icon {
    margin-top: 2px;
    margin-left: 10px;
    cursor: pointer;
}

@keyframes HmiBasicTableLoading {
    from {left: -200px; width: 30%;}
    50% {width: 30%;}
    70% {width: 70%;}
    80% { left: 50%;}
    95% {left: 120%;}
    to {left: 100%;}
}

.reset-rotate {
    animation: rotation 2s infinite linear;
}

@keyframes rotation {
    from {
            transform: rotate(0deg);
    }
    to {
            transform: rotate(359deg);
    }
}

  .spinner {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 5px;
    left: 50%;
    margin-left: -10px;
  }
  
  .double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #d8a503;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;    
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
  }
  .spinner div.green {
    background-color: #067f06;
  }
  .double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }
  
  @-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
  }
  
  @keyframes sk-bounce {
    0%, 100% { 
      transform: scale(0.0);
      -webkit-transform: scale(0.0);
    } 50% { 
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
  }


pre {
  white-space: pre-wrap;
}

.formFieldsAlignments {
  display: flex !important;
  flex-wrap: wrap !important;
}

@media screen and (max-width: 1000px){
  .formFieldsAlignments {
    display: block !important;
    flex-wrap: none !important;
  }
  .signup-form .form-group {
    max-width: 100%;
  }
  .mt-mb-6{
    margin-top: 15px;
  }
  .searchCustomWidth{
    width: 100% !important;
    margin-left: 2px;
    margin-top: -8px;
  }
}

.displayFlexCustom{
  display: flex !important;
}

.backgroungNone {
  background-color: transparent !important;
  text-align: left !important;
}

.searchCustom {
  margin-left: auto !important;
  display: flex !important;
  color: #429ad6 !important;
}

.searchCustomWidth{
  width: 300px;
  margin-left: 2px;
}
.table th, .table td {
  padding: 0.75rem 0.5rem;
}

.btn-custom {
  color: #212529 !important;
  background-color: #3bd9b2 !important;
  border-color: #31d7ae !important;
}

.export-title {
  padding-bottom: 12px;
  margin-left: 0px;
  text-align: left !important;
}

.export-button {
  width: 74px;
  height: 36px;
  padding: 3px;
  margin-left: 15px;
}
#hmi-loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  width: 100%;
  height: 100%;
}
#hmi-loader-wrapper.page-loader {
  position: fixed;
}

.selected-info {
  margin-left: 10px;
  background-color: #bdbec3;
  padding: 3px 18px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.user-filter {
  .btn-yellow {
    background: rgb(216, 165, 4);
    border-color: rgb(216, 165, 4);    
  }
}

