@mixin transition($property: all, $duration: 1s) {
    transition: width $duration;
    -moz-transition: width $duration;
    -webkit-transition: width $duration;
}

@mixin flex-layout($direction: row) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: $direction;
}

@mixin flex-align-center($direction: row) {
    @include flex-layout($direction);
    align-items: center;
    justify-content: center;  
}

@mixin flex-justify-center($direction: row) {
    @include flex-layout($direction);    
    justify-content: center;  
}

@mixin flex-size($grow: 1, $shrink: 1, $basis: 100%) {
    flex: $grow $shrink $basis;
    max-width: $basis;
}

@mixin absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}