/* .InputElement:focus {
    outline: none;
    background-color: #ccc;
} */

.Invalid {
    border: 1px solid #ff748d !important;
    background-color: #fff7f6 !important;
    margin-bottom: 0;
}

.InvalidMessage {
    font-size: 14px;
    background: #ff748d;
    border-radius: 0;
    text-align: center;
    color: #fff;
    margin-top: 5px;
    display: inline-block;
    padding: 0 4px;
    margin-bottom: 0;
    background: #D9585C;
    position: absolute;
    bottom: -21px;
    left:0;
}
.InvalidMessage:after {
    content: '';
    position: absolute;
    top: -10px;
    left: 11px;
    display: block;
    margin-left: -11px;
    border: solid;
    border-color: #D9585C transparent #D9585C;
    border-width: 0 10px 10px;
}
.isVerifyMessage {
    font-size: 14px;
    background: #ffc000;
    border-radius: 2px;
    text-align: center;
    color: #fff;
    margin-top: 5px;
    max-width: 300px;
}