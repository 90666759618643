// === COLORS ===
$text-color: #000;
$secondary-text-color: #fff;
$theme-color: #222952;
$secondary-color: #66e1c3;
$error-theme: #fbd8d8;
$error-color: #b6141d;
$success-theme: #a1f7b0;
$success-color: #222952;

// === Sizes ===
$border-radious: 0.25em;
