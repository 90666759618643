.hmiLoaderWrapper {
    position: absolute;
    top: 0;
    left: 0;
    background-color: hsla(0, 0%, 0%, 0.4);
    z-index: 100;
}

.appLoading {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -16px;
    margin-top: -19px;
}
.hmiLoadingSpinner {
    -webkit-animation: hmiLoadingSpinner .6s infinite linear;
    animation: hmiLoadingSpinner .6s infinite linear;
    border: 2px solid #d9d9d9;
    border-radius: 50%;
    border-top-color: #0079a5;
    display: inline-block;
    height: 32px;
    width: 32px;
}

  @keyframes hmiLoadingSpinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
  }
